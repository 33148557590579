(function ($) {
  $(window).on("load", function () {
    const swiper = new Swiper(".swiper", {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 1,
      spaceBetween: 10,
      speed: 800,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
          //slidesPerGroup: 2,
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const swiperTestimonials = new Swiper(".swiperTestimonials", {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      centeredSlides: true,
      spaceBetween: 40,
      speed: 800,
      // Navigation arrows
      navigation: {
        nextEl: ".testimonial.swiper-button-next",
        prevEl: ".testimonial.swiper-button-prev",
      },
    });
  });
})(jQuery);
