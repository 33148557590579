window.contentLoaded(window, function (e) {
  //Add your project specific scripts here
  const offerForm = document.querySelector("#offer-form");
  const validate = document.querySelector(".f-validate");

  if (document.querySelector("body.offer-page")) {
    offerForm.addEventListener("submit", (e) => {
      e.preventDefault();
      let formRadios = Array.from(document.querySelectorAll(".f-check"));
      if (formRadios.some((el) => el.checked === true)) {
        validate.style.display = "none";
      } else {
        validate.style.display = "block";
      }
    });
  }

  if (window.innerWidth < 567) {
    if (document.querySelector("body.offer-page")) {
      document
        .querySelectorAll(".first-rentals__card")
        .forEach((el) => el.children[0].remove());
    }
  }
});

// Get Current Month Name
function getCurrentMonth() {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var now = new Date();
  var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
  var output = document.getElementsByClassName("output");

  $(".output").html(thisMonth);
}
getCurrentMonth();
